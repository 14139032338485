import React from "react";
import ParagraphHistoryTimelineElement from "./history-timeline-element/paragraph-history-timeline-element";

const ParagraphHistory = (props) => {
  return (
    <section className="paragraph paragraph-history">
      <div className="container">
        {props.content.fieldTitle && (
          <div className="row headline-wrapper">
            <div className="col-14 offset-1">
              <h2 className="headline-xl headline-with-bg">{props.content.fieldTitle}</h2>
            </div>
          </div>
        )}
        {!!props.content.fieldTimelineElement?.length &&
          props.content.fieldTimelineElement.map((item, index) => (
            <ParagraphHistoryTimelineElement item={item} key={index} index={index} />
          ))}
      </div>
    </section>
  );
};

export default ParagraphHistory;
