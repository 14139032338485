import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const FontSizeSettings = (props) => {
  const [fontSize, setFontSize] = useState(props.min);
  const fontSizeSettingsRef = useRef();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--base-font-size",
      fontSize + "rem"
    );
  }, [fontSize]);

  useEffect(() => {
    const outsideClickHandler = (e) => {
      !fontSizeSettingsRef.current.contains(e.target) && props.onClose();
    };

    setTimeout(() => {
      document.addEventListener("click", outsideClickHandler);
    }, 50);

    return () => {
      document.removeEventListener("click", outsideClickHandler);
    };
  }, [props.open, props.onClose]);

  return (
    <div
      className="header-meta-sub-menu menu-font-size"
      ref={fontSizeSettingsRef}
    >
      <div className="inner-wrapper">
        <span className="sub-menu-label">
          <FormattedMessage id="accessibility.font_size" />
        </span>
        <div className="font-size-controls">
          <span className="font-size-from">A</span>
          <div className="slider-wrapper">
            <input
              type="range"
              min={props.min}
              max={props.max}
              step={props.step}
              value={fontSize}
              onChange={(e) => setFontSize(e.target.value)}
            />
          </div>
          <span className="font-size-to">A</span>
        </div>
      </div>
    </div>
  );
};

FontSizeSettings.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  open: PropTypes.boolean,
  onClose: PropTypes.func.isRequired,
};

export default FontSizeSettings;
