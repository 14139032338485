import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphBilderSlider extends Component {
  state = {
    sliderSettings: {
      adaptiveHeight: false,
      arrows: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
      arrows: false,
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true,
      [`format-${this.props.content.fieldFormat}`]: true,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldFormat === "full" ? (
          <ErrorBoundary>
            <ComponentBildSlider
              wrapCaption={true}
              bilder={this.props.content.fieldBilderWCaption}
              sliderSettings={this.state.sliderSettings}
              globalCaption={this.props.content.fieldBildunterschrift}
            />
          </ErrorBoundary>
        ) : (
          <div className="container">
            <div className="row">
              <div
                className={classNames({
                  "col-16": true,
                  "col-lg-10 offset-lg-2":
                    this.props.content.fieldFormat === "center",
                  "col-lg-10 offset-lg-1": this.props.content.fieldFormat === "left",
                  "col-lg-10 offset-lg-4":
                    this.props.content.fieldFormat === "right",
                })}
              >
                <ErrorBoundary>
                  <ComponentBildSlider
                    bilder={this.props.content.fieldBilderWCaption}
                    sliderSettings={this.state.sliderSettings}
                    globalCaption={this.props.content.fieldBildunterschrift}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
