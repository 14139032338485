import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";

/**
 * Use this to render links from Drupal - internal and external links are
 * differentiated.
 */
const Link = (props) => (
  <>
    {props.link.url.routed ? (
      <ReactRouterLink className={props.className} to={props.link.url.path}>
        {props.link.title}
      </ReactRouterLink>
    ) : (
      <a
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
        href={props.link.url.path}
      >
        {props.link.title}
      </a>
    )}
  </>
);

export const LinkPropType = PropTypes.shape({
  url: PropTypes.shape({
    routed: PropTypes.bool,
    path: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
});

Link.propTypes = {
  className: PropTypes.string,
  link: LinkPropType.isRequired,
};

export default Link;
