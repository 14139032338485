import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserListWrap from "./components/component-teaserlist-wrap";
import TeaserEventList from "./events/component-teaser-event-list";
import ComponentTeaserlistCarousel from "./components/component-teaserlist-carousel";
import Link from "../../../link/link";
import { Link as RouterLink } from "react-router-dom";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../teaser-base/project/teaser-projekt";
import { Animation } from "../../../../hoc/with-animation";

/**
 * This is the component for the "Eierlegende Wollmilchsau" paragraph.
 * Try not not to use this! This is just for reference.
 *
 * Usage: Copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
const ParagraphTeaserList = (props) => {
  const layout = props.content.fieldLayout || "vertical";
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    [`paragraph-teaser-list-${props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
    "paragraph paragraph-teaser-list-slick": props.content.fieldKarussel,
    [`paragraph-teaser-list-${props.content.fieldTyp}`]: true,
    [`layout-${layout}`]: true,
  });

  let manualNodes = props.content.fieldBeitraegeAll;

  switch (props.content.fieldTyp) {
    case "news": {
      manualNodes = props.content.fieldBeitraegeNews;

      break;
    }
    case "person": {
      manualNodes = props.content.fieldBeitraegePerson;

      break;
    }
    case "veranstaltung": {
      manualNodes = props.content.fieldBeitraegeVeranstaltung;

      break;
    }
    default: {
      manualNodes = props.content.fieldBeitraegeAll;
    }
  }

  const InnerWrapper = ({ variant, children }) => {
    if (variant === "vertical") {
      return (
        <div className="container">
          <div className="row">
            <div className="col-16">{children}</div>
          </div>
        </div>
      );
    } else {
      return <div className="col-16 col-md-8 teaser-wrapper">{children}</div>;
    }
  };

  const OuterWrapper = ({ variant, children }) => {
    if (variant === "vertical") {
      return children;
    } else {
      return (
        <div className="container">
          <div
            className={classNames({
              row: true,
              "flex-row-reverse": props.content.fieldImagePosition === "left",
            })}
          >
            {children}
          </div>
        </div>
      );
    }
  };

  return (
    <section className={sectionClassNames}>
      <OuterWrapper variant={layout}>
        {props.content.fieldHeading && (
          <Animation>
            {props.content.fieldLayout === "vertical" ? (
              <div className="container">
                <div className="row">
                  <div className="col-14 offset-1">
                    <h2 className="headline-xl headline-with-bg">
                      {props.content.fieldHeading}
                    </h2>
                    {props.content.fieldLinkOverview && (
                      <div>
                        <RouterLink
                          to={props.content.fieldLinkOverview.url.path}
                          className="overview-link line-link"
                        >
                          {props.content.fieldLinkOverview.title}
                        </RouterLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-15 col-md-7 offset-1 headline-wrapper">
                <div>
                  <h2 className="headline-xl headline-with-bg">
                    {props.content.fieldHeading}
                  </h2>
                  {props.content.fieldLinkOverview && (
                    <div className="text">
                      <RouterLink
                        to={props.content.fieldLinkOverview.url.path}
                        className="overview-link line-link"
                      >
                        {props.content.fieldLinkOverview.title}
                      </RouterLink>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Animation>
        )}

        <InnerWrapper variant={layout}>
          <Animation>
            <ErrorBoundary>
              <TeaserListWrap
                manualNodes={manualNodes}
                id={props.content.entityId}
                nodesConfig={props.content.fieldAutmatischOderManuell}
                count={props.content.fieldAnzahlDerAnzuzeigendenI}
                type={props.content.fieldTyp}
                pagerFullPage={
                  props.content.fieldPagerAufVollseiten
                    ? props.content.entityId
                    : false
                }
                pagerFullPageOverviewLink={props.content.fieldLinkZurUebersicht}
                tags={props.content.fieldSchlagwort}
                layout={layout}
              />
            </ErrorBoundary>
          </Animation>

          {props.content.fieldMehrMeldungenButtonZeig &&
            props.content.fieldMehrMeldungenButton.title && (
              <div className="container">
                <div className="row">
                  <div className="col-16 d-md-flex justify-content-md-end">
                    <ErrorBoundary>
                      <Link
                        link={props.content.fieldMehrMeldungenButton}
                        className="btn btn-primary more"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            )}
        </InnerWrapper>
      </OuterWrapper>
    </section>
  );
};

ParagraphTeaserList.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
    fieldHeading: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldBeitraegeAll: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.oneOfType([
          teaserNewsPropTypes,
          teaserEventPropTypes,
          teaserPersonPropTypes,
          teaserGeneralPropTypes,
          teaserProjectPropTypes,
        ]),
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldBeitraegeNews: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserNewsPropTypes,
      })
    ),
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserList;
