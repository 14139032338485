import React from "react";
import TeaserArticle from "../../../../teaser-base/article/teaser-article";
import { Link } from "react-router-dom";
import { Animation } from "../../../../../hoc/with-animation";

const ParagraphExtendedTeaserOverviewArticles = (props) => {
  return (
    <>
      {(props.content.fieldTitle || props.content.fieldLinkZurUebersicht) && (
        <div className="container">
          <div className="row">
            <div className="col-14 offset-1">
              <div className="teaser-overview-head">
                {props.content.fieldTitle && (
                  <h2>{props.content.fieldTitle}</h2>
                )}
                {props.content.fieldLinkZurUebersicht && (
                  <Link to={props.content.fieldLinkZurUebersicht.url.path}>
                    <small>{props.content.fieldLinkZurUebersicht.title}</small>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          {!!props.itemsToShow.length &&
            props.itemsToShow.map((item, i) => (
              <Animation key={i} delay={(i % 3) * 100}>
                <div className="col-third">
                  <TeaserArticle
                    item={item}
                    pagerFullPage={
                      props.content.fieldPagerAufVollseiten
                        ? this.props.content.entityId
                        : false
                    }
                  />
                </div>
              </Animation>
            ))}
        </div>
      </div>
    </>
  );
};

export default ParagraphExtendedTeaserOverviewArticles;
