import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Use this component for rendering images queried from Drupal.
 */
class Image extends Component {
  render() {
    let { alt, title } = this.props.data;

    if (!alt && this.props.caption) {
      alt = this.props.caption;
    } else if (!alt && this.props.nodeTitle) {
      alt = this.props.nodeTitle;
    }

    if (!title && this.props.caption) {
      title = this.props.caption;
    } else if (!title && this.props.nodeTitle) {
      title = this.props.nodeTitle;
    }

    const imageStyle = this.props.style || "style";

    if (this.props.fullPage) {
      return (
        <>
          {this.props.credit && (
            <span className="credit">© {this.props.credit}</span>
          )}
          <img
            className={this.props.className}
            src={this.props.data.styleFullPage.url}
            title={title}
            alt={alt}
            onClick={this.props.onClick}
          />
        </>
      );
    }

    if (this.props.data.mobile && this.props.data.desktop) {
      return [
        <img
          key="mobile-image"
          className={`${this.props.className} mobile-image d-block d-md-none`}
          src={this.props.data.mobile.url}
          title={title}
          alt={alt}
          onClick={this.props.onClick}
        />,
        <img
          key="desktop-image"
          className={`${this.props.className} desktop-image d-none d-md-block`}
          src={this.props.data.desktop.url}
          title={title}
          alt={alt}
          onClick={this.props.onClick}
        />,
      ];
    }

    return (
      <div className="img-wrapper">
        <img
          className={this.props.className}
          src={this.props.data[imageStyle].url}
          title={title}
          alt={alt}
          onClick={this.props.onClick}
        />
        {(this.props.caption ||
          this.props.credit ||
          this.props.globalCaption) && (
          <div
            className={classNames({
              "caption-wrap": true,
              "col-16": this.props.wrapCaption,
            })}
          >
            {this.props.caption && (
              <span className="caption">{this.props.caption}</span>
            )}
            {this.props.globalCaption && !this.props.caption && (
              <span className="caption">{this.props.globalCaption}</span>
            )}
            {this.props.credit && (
              <span className="credit">© {this.props.credit}</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

Image.propTypes = {
  /**
   * The image caption.
   */
  caption: PropTypes.string,
  /**
   * Used as fallback alt="" value.
   */
  nodeTitle: PropTypes.string,
  /**
   * The image credit.
   */
  credit: PropTypes.string,
  data: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    styleFullPage: PropTypes.shape({
      url: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  /**
   * Custom class names to add to the component.
   */
  className: PropTypes.string,
  /**
   * Only provided if used inside an image gallery component.
   */
  globalCaption: PropTypes.string,
  /**
   * In fullpage-mode, image and caption are swapped.
   */
  fullPage: PropTypes.bool,
  /**
   * Wrap caption?
   */
  wrapCaption: PropTypes.bool,
  style: PropTypes.string,
};

export default Image;
