import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { UpToTop24 } from "@carbon/icons-react";
import { animateScroll as scroll } from "react-scroll";
import Masonry from "react-masonry-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LogoButton from "../../general-components/logo-button";
import menuQuery from "../../general-components/menu-query.graphql";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class FooterSitemap extends Component {
  state = {};

  render() {
    return (
      <section className="footer-sitemap">
        <nav>
          {!this.props.mainMenuQuery.loading &&
            this.props.mainMenuQuery.menuByName.links.map((item, index) => (
              <span className="link-wrapper" key={index}>
                <Link key={index} to={item.url.path}>
                  {item.label}
                </Link>
              </span>
            ))}
        </nav>
      </section>
    );
  }
}

FooterSitemap.propTypes = {
  mainMenuQuery: PropTypes.object.isRequired,
  menu: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(
  graphql(menuQuery, {
    options: (props) => ({
      variables: {
        name: props.menu,
        language: props.currentLanguage
          ? props.currentLanguage.toUpperCase()
          : "DE",
      },
    }),
    name: "mainMenuQuery",
  })(FooterSitemap)
);
