import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import TeaserArticle from "../../../teaser-base/article/teaser-article";

const ParagraphSimpleTeaserList = ({ content }) => {
  const teaserData = content.fieldTeaser.map((teaser) => ({
    text: teaser.entity.fieldText,
    image: teaser.entity.fieldTeaserbild?.entity.fieldMediaImage,
    url: teaser.entity.fieldLink?.url.path,
    title: teaser.entity.fieldTitle,
  }));

  const ConditionalWrapper = ({ link, children }) => {
    if (link) return <Link to={link}>{children}</Link>;
    return children;
  };

  ConditionalWrapper.propTypes = {
    link: PropTypes.object,
    children: PropTypes.element,
  };

  return (
    <section className="paragraph paragraph-simple-teaser-list">
      <div className="container-fluid">
        <div className="row">
          {content.fieldTeaser.map((teaser, i) => (
            <div key={i} className="col-third">
              <TeaserArticle
                item={{
                  ...teaser.entity,
                  title: teaser.entity.fieldTitle
                }}
                adminApp={false}
                entityId={false}
                location={false}
                pagerFullPage={false}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphSimpleTeaserList.propTypes = {
  content: PropTypes.shape({
    fieldTeaser: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldText: PropTypes.object,
          fieldTeaserbild: PropTypes.object,
          fieldLink: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphSimpleTeaserList;

/* <div
    key={i}
    className={classNames({
      "col-14": true,
      "offset-2 col-lg-5": teaserData.length !== 3,
      "offset-1 col-lg-4": teaserData.length === 3,
      "teaser-item": true
    })}
  >
    <ConditionalWrapper link={teaser.url}>
      <div className="image-wrapper">
        {teaser.image && (
          <img src={teaser.image.style.url} alt={teaser.image.alt} />
        )}
      </div>
      {teaser.title && (
        <h3 className="title">{teaser.title}</h3>
      )}
      {teaser.text && (
        <div
          dangerouslySetInnerHTML={{ __html: teaser.text.processed }}
          className="text"
        />
      )}
    </ConditionalWrapper>
  </div> */
