import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";
import VisitorMenu from "./visitor-menu";

const LanguageSwitch = (props) => {
  const { i18n } = useSelector((reduxStore) => ({ i18n: reduxStore.i18n }));
  if (i18n) {
    return (
      <>
        <a onClick={props.onClick} className="link language-switch">
          <span className="label">
            <span
              className={classNames({
                "active-lang": i18n.currentLanguage === "de",
              })}
            >
              DE
            </span>
            |
            <span
              className={classNames({
                "active-lang": i18n.currentLanguage === "en",
              })}
            >
              EN
            </span>
          </span>
        </a>
        {props.open && props.destination &&
          ReactDOM.createPortal(
            <VisitorMenu i18n={i18n} onClose={props.onClose} />,
            props.destination
          )}
      </>
    );
  }

  return false;
};

LanguageSwitch.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  destination: PropTypes.object,
};

export default LanguageSwitch;
