import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../teaser-base/person/teaser-person";
import ErrorBoundary from "../../../../error-boundary";

/**
 * Maximum of three news or person entries.
 *
 * @todo Events.
 */
class ParagraphDossier extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true,
      "with-background-image": this.props.content.fieldImage !== null,
    });

    let customStyle = {};

    if (this.props.content.fieldImage !== null) {
      customStyle = {
        background: `url(${this.props.content.fieldImage.entity.fieldMediaImage.style.url})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldImage !== null && (
          <div className="background-image" style={customStyle} />
        )}
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-10 offset-md-1">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-16 col-md-14 offset-md-1">
              <div className="row items">
                {typeof this.props.content.fieldBeitraegeDossier[0] !==
                "undefined" ? (
                  <div className="col-16 col-md-8">
                    {(() => {
                      /* TODO: Veranstaltung */
                      switch (
                        this.props.content.fieldBeitraegeDossier[0].entity
                          .entityBundle
                      ) {
                        case "news":
                          return (
                            <ErrorBoundary>
                              <TeaserNews
                                item={
                                  this.props.content.fieldBeitraegeDossier[0]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        case "person":
                          return (
                            <ErrorBoundary>
                              <TeaserPerson
                                item={
                                  this.props.content.fieldBeitraegeDossier[0]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                ) : null}
                {typeof this.props.content.fieldBeitraegeDossier[1] !==
                "undefined" ? (
                  <div className="col-16 col-md-4">
                    {(() => {
                      /* TODO: Veranstaltung */
                      switch (
                        this.props.content.fieldBeitraegeDossier[1].entity
                          .entityBundle
                      ) {
                        case "news":
                          return (
                            <ErrorBoundary>
                              <TeaserNews
                                item={
                                  this.props.content.fieldBeitraegeDossier[1]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        case "person":
                          return (
                            <ErrorBoundary>
                              <TeaserPerson
                                item={
                                  this.props.content.fieldBeitraegeDossier[1]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                ) : null}
                {typeof this.props.content.fieldBeitraegeDossier[2] !==
                "undefined" ? (
                  <div className="col-16 col-md-4">
                    {(() => {
                      /* TODO: Veranstaltung */
                      switch (
                        this.props.content.fieldBeitraegeDossier[2].entity
                          .entityBundle
                      ) {
                        case "news":
                          return (
                            <ErrorBoundary>
                              <TeaserNews
                                item={
                                  this.props.content.fieldBeitraegeDossier[2]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        case "person":
                          return (
                            <ErrorBoundary>
                              <TeaserPerson
                                item={
                                  this.props.content.fieldBeitraegeDossier[2]
                                    .entity
                                }
                              />
                            </ErrorBoundary>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldBeitraegeDossier: PropTypes.arrayOf(
      PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
    ),
  }),
};

export default ParagraphDossier;
