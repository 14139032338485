import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const ThemeSwitch = () => {
  const [theme, setTheme] = useState("light");

  const toggleHighContrast = () => {
    setTheme((prev) => {
      if (prev === "light") return "dark";
      if (prev === "dark") return "light";
    });
  };

  useEffect(() => {
    const html = document.documentElement;
    html.dataset.theme = theme;
  }, [theme]);

  return (
    <a onClick={toggleHighContrast} className="link">
      <span className="icon-wrapper">
        {theme === "light" && <span className="icon icon-moon" />}
        {theme === "dark" && <span className="icon icon-sun" />}
      </span>
      <span className="label">
        <FormattedMessage id="accessibility.contrast" />
      </span>
    </a>
  );
};

export default ThemeSwitch;
