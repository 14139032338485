import React from "react";
import Image from "../../../../image/image";
import { Animation } from "../../../../../hoc/with-animation";
import classNames from "classnames";

const ParagraphHistoryTimelineElement = ({ item, index }) => {
  return (
    <div
      className={`row history-timeline-element${
        item.entity.fieldHistoryImage ? " with-image" : ""
      }`}
    >
      <div className="col-14 col-md-7 offset-1 image-wrapper">
        <Animation>
          <div>
            {item.entity.fieldHistoryImage && (
              <Image
                data={item.entity.fieldHistoryImage.entity.fieldMediaImage}
                caption={item.entity.fieldHistoryImage.entity.fieldCaption}
                credit={item.entity.fieldHistoryImage.entity.fieldCredit}
              />
            )}
          </div>
        </Animation>
      </div>
      <div className="col-14 col-md-6 offset-1 text-wrapper">
        <Animation delay={200}>
          <div
            className="text content-padding reset-content-padding-lg"
            dangerouslySetInnerHTML={{
              __html: item.entity.fieldText.processed,
            }}
          />
        </Animation>
      </div>
    </div>
  );
};

export default ParagraphHistoryTimelineElement;
