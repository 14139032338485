import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { teaserNewsPropTypes } from "../../../../teaser-base/news/teaser-news";
import { teaserPersonPropTypes } from "../../../../teaser-base/person/teaser-person";

class ComponentFilterCloud extends Component {
  static defaultProps = { wrap: true, preventEmptyResult: false };

  getTagsFromItems = (items, filterField) => {
    let tags = [];

    for (const item of items) {
      if (item[filterField] == null) {
        continue;
      }
      // Text field.
      if (typeof item[filterField] === "string") {
        if (!tags.some(({ entityId }) => entityId === item[filterField])) {
          tags = [
            ...tags,
            {
              entityId: item[filterField],
              name: item[filterField],
            },
          ];
        }

        continue;
      }

      // Single Entity reference field.
      if (
        typeof item[filterField] === "object" &&
        !Array.isArray(item[filterField]) &&
        item[filterField].entity.name !== null &&
        item[filterField].entity.entityId !== null
      ) {
        if (
          !tags.some(
            ({ entityId }) => entityId === item[filterField].entity.entityId
          )
        ) {
          tags = [...tags, item[filterField].entity];
        }

        continue;
      }

      // Multi Entity reference field.
      for (const tag of item[filterField]) {
        if (
          tag.entity !== null &&
          tag.entity.name !== null &&
          !tags.some(({ entityId }) => entityId === tag.entity.entityId)
        ) {
          tags = [...tags, tag.entity];
        }
      }
    }

    return tags;
  };

  getFilters = (items, filtersInput) => {
    const filters = {};

    for (const filter of filtersInput) {
      filters[filter] = this.getTagsFromItems(items, filter);
    }

    return filters;
  };

  state = {
    tags: this.props.items
      ? this.getFilters(this.props.items, this.props.filter)
      : [],
  };

  render() {
    const filters = Object.keys(this.state.tags);

    const filter = filters.map((filterName, index) => {
      const items = this.state.tags[filterName];

      return (
        <React.Fragment key={index}>
          {this.props.labels && this.props.labels[index] ? (
            <div className="col-16">
              <h3>{this.props.labels[index]}</h3>
            </div>
          ) : null}
          <div className="col-16">
            {items.map((item, index) => {
              if (
                this.props.preventEmptyResult &&
                typeof this.props.filterItems === "function"
              ) {
                const matchingItems = this.props.filterItems([
                  ...this.props.activeFilter,
                  {
                    ...item,
                    field: filterName,
                  },
                ]);

                if (matchingItems.length === 0) {
                  return null;
                }
              }

              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames({
                    filter: true,
                    active:
                      this.props.activeFilter &&
                      this.props.activeFilter.filter(
                        (filterItem) =>
                          filterItem.entityId === item.entityId &&
                          filterItem.field === filterName
                      ).length > 0,
                  })}
                  key={index}
                  onClick={() =>
                    this.props.changeActiveFilter({
                      ...item,
                      field: filterName,
                    })
                  }
                >
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      );
    });

    if (this.props.wrap) {
      return (
        <section className="filter-cloud">
          <div className="container">
            <div className="row">{filter}</div>
          </div>
        </section>
      );
    }

    return (
      <section className="filter-cloud">
        <div>{filter}</div>
      </section>
    );
  }
}

ComponentFilterCloud.propTypes = {
  /**
   * The active filter.
   */
  activeFilter: PropTypes.array,
  /**
   * The function to change the active filter.
   */
  changeActiveFilter: PropTypes.func.isRequired,
  /**
   * The filter function used to filter the items. Used for the "prevent zero
   * results" feature.
   */
  filterItems: PropTypes.func,
  /**
   * The items to be filtered.
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
  ),
  /**
   * The name of the filtered field.
   */
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The labels of the filters.
   */
  labels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether to prevent zero results.
   */
  preventEmptyResult: PropTypes.bool,
  /**
   * Whether to wrap the filter cloud in a container.
   */
  wrap: PropTypes.bool.isRequired,
};

export default ComponentFilterCloud;
