import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";

import EditButton from "../../../backend/edit-button";
import { Link } from "react-router-dom";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */

const TeaserEvent = (props) => {
  const dateTimeFormat = "dddd, DD. MMMM YYYY, HH:MM [Uhr]";
  const formattedDate = [
    !!props.item.fieldDatum.startDate &&
      moment(props.item.fieldDatum.startDate).format(dateTimeFormat),
    !!props.item.fieldDatum.endDate &&
      !props.item.fieldDatum.endDateEnabled &&
      moment(props.item.fieldDatum.endDate).format(dateTimeFormat),
  ].join(" – ");

  const formattedMeta = [
    !!formattedDate && formattedDate,
    !!props.item.fieldOrt && props.item.fieldOrt,
  ].join(", ");

  return (
    <article
      className={`node node-${props.item.entityId} node-teaser node-teaser-default teaser-veranstaltung col-16`}
    >
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
        <Link
          onClick={() =>
            props.dispatch(
              pagerFullPageContextAction(props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            props.item.entityUrl
              ? props.item.entityUrl.path
              : props.item.path.alias
          }
        >
          {props.item.fieldBild && (
            <Image
              data={props.item.fieldBild.entity.fieldMediaImage}
              nodeTitle={props.item.title}
            />
          )}
          <div className="text-wrapper">
            {formattedMeta && <small>{formattedMeta}</small>}
            <h3>{props.item.title}</h3>
          </div>
        </Link>
    </article>
  );
};

TeaserEvent.defaultProps = {
  pagerFullPage: false
}

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
