import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import Image from "../../image/image";
import EditButton from "../../../backend/edit-button";
import {teaserPersonPropTypes} from './teaser-person';

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserPersonKontakt extends Component {
  static defaultProps = { showImage: true };

  render() {
    const { item } = this.props,
      infoSection = (
        <div>
          <h3>{item.title}</h3>
          {item.fieldSchlagwort.length > 0 &&
            this.props.item.fieldSchlagwort[0].entity !== null && (
              <span className="top-line">
                {item.fieldSchlagwort[0].entity.name}
              </span>
            )}
          <div className="contact">
            {item.fieldMail && (
              <a className="mail" href={`mailto:${item.fieldMail}`}>
                {item.fieldMail}
              </a>
            )}
            {item.fieldTelefon && (
              <a
                className="phone"
                href={`tel:${item.fieldTelefon
                  .replace(/ /g, "")
                  .replace(/\([\s\S]*?\)/g, "")}`}
              >
                {item.fieldTelefon}
              </a>
            )}
            {item.fieldMobil && (
              <a
                className="mobil"
                href={`tel:${item.fieldMobil
                  .replace(/ /g, "")
                  .replace(/\([\s\S]*?\)/g, "")}`}
              >
                {item.fieldMobil}
              </a>
            )}
          </div>
          {item.fieldBeschreibung && (
            <div
              className="info"
              dangerouslySetInnerHTML={{ __html: item.fieldBeschreibung.value }}
            />
          )}
          <div className="buttons">
            {item.fieldMail && (
              <a className="btn btn-primary" href={`mailto:${item.fieldMail}`}>
                Mail Schreiben
              </a>
            )}
          </div>
        </div>
      );

    return (
      <article className="node node-teaser teaser-person-kontakt">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="row">
          {this.props.showImage && (
            <div className="col-16 col-lg-8 this.props.showImage">
              <Image
                data={item.fieldBildWCaption.entity.fieldMediaImage}
                nodeTitle={item.title}
              />
            </div>
          )}
          <div
            className={classNames({
              "col-16": true,
              "col-lg-8": this.props.showImage,
            })}
          >
            {infoSection}
          </div>
        </div>
      </article>
    );
  }
}

TeaserPersonKontakt.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  showImage: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonKontakt));
