import React, { useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import Image from "../../../image/image";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */
const ComponentBildSlider = (props) => {
  const slider = useRef();

  return (
    <>
      {props.bilder.length > 1 ? (
        <div className="slider-wrapper">
          <Slider {...props.sliderSettings} ref={slider}>
            {props.bilder.map((item, index) => (
              <article key={index} className="media-image">
                <Image
                  data={item.entity.fieldMediaImage}
                  credit={item.entity.fieldCredit}
                  caption={item.entity.fieldCaption}
                  globalCaption={props.globalCaption}
                  wrapCaption={props.wrapCaption}
                />
              </article>
            ))}
          </Slider>
          <div className="controls">
            <button
              aria-label="prev"
              className="slick-prev"
              onClick={() => slider?.current?.slickPrev()}
            >
              <span className="icon icon-arrow-left" />
            </button>
            <button
              aria-label="next"
              className="slick-next"
              onClick={() => slider?.current?.slickNext()}
            >
              <span className="icon icon-arrow-right" />
            </button>
          </div>
        </div>
      ) : (
        <article className="media-image">
          <Image
            data={props.bilder[0].entity.fieldMediaImage}
            credit={props.bilder[0].entity.fieldCredit}
            caption={props.bilder[0].entity.fieldCaption}
            globalCaption={props.globalCaption}
            wrapCaption={props.wrapCaption}
          />
        </article>
      )}
    </>
  );
};

ComponentBildSlider.propTypes = {
  bilder: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })
  ),
  globalCaption: PropTypes.string,
  sliderSettings: PropTypes.object.isRequired,
  wrapCaption: PropTypes.bool,
};

export default ComponentBildSlider;
