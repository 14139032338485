import React from "react";
import Image from "../../image/image";

const TeaserEducationalResourceOverview = ({ item }) => {
  return (
    <article className="node node-teaser teaser-educational-resource teaser-educational-resource-overview">
      <Image data={item.fieldTeaserImage.entity.fieldMediaImage} />
      <h3>{item.entityLabel}</h3>
      <p>{item.fieldBody}</p>
      <div className="link-list-wrapper">
        <ul>
          {item.fieldReferencedMedia.length &&
            item.fieldReferencedMedia.map((media, index) => (
              <li key={index}>
                <a
                  href={media.entity.fieldMediaFile.entity.url}
                  target="_blank"
                  download={media.entity.fieldMediaFile.entity.filename}
                >
                  {media.entity.entityLabel}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </article>
  );
};

export default TeaserEducationalResourceOverview;
