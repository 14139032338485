import { withApollo } from "@apollo/client/react/hoc";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";

const Search = React.forwardRef((props, ref) => {
  const inputRef = useRef();
  const history = useHistory();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (props.focus) inputRef.current.focus();
    if (props.initialRequest) setQuery(props.initialRequest);
  }, []);

  // trigger request whenever query state changes
  useEffect(() => {
    if (query) redirect();
  }, [query]);

  const redirect = () => {
    // trigger onRequest function if exists
    props.onRequest && props.onRequest();

    // redirect to search page with search params
    history.push({
      pathname: `${props.intl.locale === "en" ? "/en" : ""}/suche`,
      search: "?" + new URLSearchParams({ q: query }),
    });
  };

  // handle enter input
  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setQuery(inputRef.current.value);
    }
  };

  return (
    <div className="search">
      <input
        ref={inputRef}
        autoComplete="off"
        type="search"
        name="searchString"
        onKeyDown={keyDownHandler}
        defaultValue={props.defaultValue}
        placeholder={props.intl.formatMessage({ id: "search.enter_query" })}
      />
      <button onClick={() => setQuery(inputRef.current.value)}>
        <FormattedMessage id="search.search" />
      </button>
    </div>
  );
});

export default withApollo(withRouter(injectIntl(Search)));
