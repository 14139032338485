import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import FontSizeSettings from "./font-size-settings";

const FontSizeSwitch = (props) => {
  return (
    <>
      <a onClick={props.onClick} className="link">
        <span className="icon-wrapper">
          <span className="icon icon-font-size"></span>
        </span>
        <span className="label">
          <FormattedMessage id="accessibility.font_size" />
        </span>
      </a>
      {props.open &&
        ReactDOM.createPortal(
          <FontSizeSettings
            min={props.min}
            max={props.max}
            step={props.step}
            onClose={props.onClose}
          />,
          props.destination
        )}
    </>
  );
};

FontSizeSwitch.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export default FontSizeSwitch;
