import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Simple pagination component
 */
const Pagination = ({ total, steps = 10, value = 0, onChange }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    setCurrentIndex(value / steps + 1);
  }, [value]);

  const clickHandler = (offset) => {
    if (value === offset) return; // if offset equals current offset, do nothing
    if (offset < 0 || offset > total) return; // if offset is out of range
    if (!onChange) return; // if there is no onChange func, do nothing

    // trigger onChange function
    onChange(offset);
  };

  /**
   * This will create a number button array with never showing more than 5 buttons.
   */
  useEffect(() => {
    const pattern = [-2, -1, 0, 1, 2];
    setButtons(() => {
      const newButtons = [];
      pattern.forEach((item) => {
        const totalPages = Math.ceil(total / steps);

        // edge cases: first, second, last but one and last pages
        let patternOffset = 0;
        if (currentIndex === 1) patternOffset = 2;
        if (currentIndex === 2) patternOffset = 1;
        if (totalPages - currentIndex == 1) patternOffset = -1;
        if (totalPages - currentIndex == 0) patternOffset = -2;

        const number = currentIndex + item + patternOffset;

        if (number >= 1 && number <= Math.ceil(total / steps)) {
          newButtons.push({
            number: number,
            offset: steps * (number - 1),
          });
        }
      });

      return newButtons;
    });
  }, [currentIndex]);

  return (
    <div className="pagination">
      <a
        className="btn pagination-button arrow"
        onClick={() => clickHandler(value - steps)}
        disabled={value - steps < 0}
        role="button"
      >
        <span className="icon icon-arrow-left"></span>
      </a>
      {buttons.map((button, i) => (
        <a
          key={i}
          className={classNames({
            "btn pagination-button number": true,
            active: button.offset === value,
          })}
          onClick={() => clickHandler(button.offset)}
          role="button"
        >
          {button.number}
        </a>
      ))}
      <a
        className="btn pagination-button arrow"
        onClick={() => clickHandler(value + steps)}
        disabled={value + steps > total}
        role="button"
      >
        <span className="icon icon-arrow-right"></span>
      </a>
    </div>
  );
};

Pagination.propTypes = {
  steps: PropTypes.number,
  total: PropTypes.number.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
