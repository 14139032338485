import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorBoundary from "../../../../error-boundary";
import Link from "../../../link/link";
import Image from "../../../image/image";
import { Animation } from "../../../../hoc/with-animation";
class ParagraphHighlightedInformation extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-highlighted-information": true,
      "theme-1": this.props.content.fieldColorScheme === "theme-1",
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          {this.props.content.fieldTitle && (
            <div className="row">
              <div className="col-14 offset-1">
                <h2 className="headline-xl headline-with-bg">
                  {this.props.content.fieldTitle}
                </h2>
              </div>
            </div>
          )}
          <div className="row outer-bounds">
            {this.props.content.fieldParagraph &&
              this.props.content.fieldParagraph.map((item, index) => (
                <ErrorBoundary key={index}>
                  <Animation delay={index * 100}>
                    <div className="col-16 col-md-6 offset-md-1">
                      <div className="flex-wrapper">
                        <div className="icon-wrapper">
                          {item.entity.fieldIcon && (
                            <img
                              src={
                                item.entity.fieldIcon.entity.fieldMediaImage.url
                              }
                            />
                          )}
                        </div>
                        <div className="text-wrapper">
                          {item.entity.fieldTitle && (
                            <h3 className="headline-m">
                              {item.entity.fieldTitle}
                            </h3>
                          )}
                          {item.entity.fieldBeschreibung && (
                            <div
                              className="discription text"
                              dangerouslySetInnerHTML={{
                                __html: item.entity.fieldBeschreibung.processed,
                              }}
                            />
                          )}
                          {item.entity.fieldLink && (
                            <Link
                              className="line-link"
                              link={item.entity.fieldLink}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Animation>
                </ErrorBoundary>
              ))}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphHighlightedInformation.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldAbsatz: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphHighlightedInformation;
