import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../../image/image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
class TeaserPersonOverview extends Component {
  state = { isOpen: false };

  toggleOverlay = () => {
    if (this.props.item.fieldBeschreibung) {
      if (this.state.isOpen) {
        this.setState({ isOpen: false }, () => {
          document.querySelector("html").style.overflow = "visible";
        });
      } else {
        this.setState({ isOpen: true }, () => {
          document.querySelector("html").style.overflow = "hidden";
        });
      }
    }
  };

  render() {
    return (
      <>
        <article
          className="node node-teaser teaser-person teaser-person-overview"
          data-testid="teaser-person"
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <div className="flex-wrap">
            <div
              className="teaser-person-image-wrapper"
              onClick={() => this.toggleOverlay()}
            >
              {this.props.item.fieldBeschreibung && (
                <div className="expand-button">
                  <span className="icon icon-plus" />
                </div>
              )}
              {this.props.item.fieldBildWCaption ? (
                <Image
                  data={
                    this.props.item.fieldBildWCaption.entity.fieldMediaImage
                  }
                  nodeTitle={this.props.item.title}
                />
              ) : (
                (() => {
                  switch (this.props.item.fieldGender.entity.name) {
                    case "Weiblich":
                      return <img src="/skeleton-head-female.jpg" />;
                    case "Männlich":
                      return <img src="/skeleton-head-male.jpg" />;
                    default:
                      return <img src="/skeleton-head-male.jpg" />;
                  }
                })()
              )}
            </div>
            <div className="teaser-person-text-wrapper">
              <h4>{this.props.item.title}</h4>

              {this.props.item.fieldRolleOderFunktion.length > 0 && (
                <div className="role">
                  <span className="small">
                    {this.props.item.fieldRolleOderFunktion.map(
                      (item, index) => (
                        <React.Fragment key={index}>
                          {item.entity && (
                            <>
                              {item.entity.name}
                              {index !==
                                this.props.item.fieldRolleOderFunktion.length -
                                  1 &&
                                index <
                                  this.props.item.fieldRolleOderFunktion
                                    .length &&
                                ", "}
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </span>
                </div>
              )}

              <div className="contact-data">
                {this.props.item.fieldTelefon && (
                  <span className="phone-wrapper">
                    <FormattedMessage id="contact.phone" />{" "}
                    <a
                      className="phone"
                      href={`tel:${this.props.item.fieldTelefon
                        .replace(/ /g, "")
                        .replace(/\([\s\S]*?\)/g, "")}`}
                    >
                      {this.props.item.fieldTelefon}
                    </a>
                  </span>
                )}

                {this.props.item.fieldMail && (
                  <span className="mail-wrapper">
                    <a
                      className="mail"
                      href={`mailto:${this.props.item.fieldMail}`}
                    >
                      <FormattedMessage id="contact.send_email" />
                    </a>
                  </span>
                )}
              </div>
            </div>
          </div>
        </article>
        {this.state.isOpen && (
          <div className="person-overlay-bg">
            <div className="person-overlay">
              <TeaserPersonOverlay
                item={this.props.item}
                toggleOverlay={this.toggleOverlay}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
