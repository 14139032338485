import React from "react";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserPersonOverview from "../../../../teaser-base/person/teaser-person-overview";
import { PropTypes } from "prop-types";

const ParagraphExtendedTeaserOverviewPersons = ({ content, itemsToShow }) => {
  return (
    <div className="container-fluid">
      <div className="row section-header">
        <div className="col-16 col-xl-4 headline-wrapper">
          {content.fieldTitle && (
            <h2 className="headline-m">{content.fieldTitle}</h2>
          )}
        </div>
      </div>
      <div className="row section-body">
        <div className="col-16 col-xl-4 space-wrapper" />
        <div className="col-16 col-xl-12 teaser-wrapper">
          {!!itemsToShow.length &&
            itemsToShow.map((item, index) => (
              <React.Fragment key={index}>
                <ErrorBoundary>
                  <TeaserPersonOverview
                    item={item}
                    pagerFullPage={
                      content.fieldPagerAufVollseiten ? content.entityId : false
                    }
                  />
                </ErrorBoundary>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

ParagraphExtendedTeaserOverviewPersons.propTypes = {
  content: PropTypes.object.isRequired,
  itemsToShow: PropTypes.array.isRequired,
};

export default ParagraphExtendedTeaserOverviewPersons;
