import React, { useState } from "react";
import { Link } from "react-router-dom";

const SimpleNavigation = (props) => {
  const level = props.level || 1;
  const [openSubNav, setOpenSubNav] = useState([]);

  const mobileClickHandler = (index) => {
    setOpenSubNav((prev) => {
      // if item is already open, remove from openSubNav list, else add it to openSubNav
      if (prev.includes(index)) {
        // get item index in previous state
        const itemIndex = prev.indexOf(index);
        // remove item from previous state
        const tmp = [...prev];
        if (itemIndex > -1) {
          tmp.splice(itemIndex, 1);
        }
        return tmp;
      } else {
        return [...prev, index];
      }
    });
  };

  const getActiveClass = (item) => {
    if (
      item.url.path === props.location.pathname ||
      item.links?.map((link) => link.url.path).includes(props.location.pathname)
    ) {
      return "active";
    }

    return "";
  };

  return (
    !!props.menu.links.length && (
      <div className={`level-${level}-wrapper ${props.open ? "open" : ""}`}>
        <ul className={`level-${level}`}>
          {props.menu.links.map((item, index) => (
            <li
              key={index}
              onMouseEnter={
                props.desktop ? (e) => setOpenSubNav([index]) : undefined
              }
              onMouseLeave={
                props.desktop ? (e) => setOpenSubNav([]) : undefined
              }
              onClick={
                props.mobile ? (e) => mobileClickHandler(index) : undefined
              }
            >
              <Link
                to={loc => item.url.path || loc}
                className={`${openSubNav.includes(index) ? "open" : ""} ${
                  item?.links?.length ? "has-children" : ""
                } ${getActiveClass(item)}`}
                onClick={!item.links?.length ? props.onCloseMobileMenu : undefined}
              >
                <span className="label">{item.label}</span>
              </Link>
              {!!item.links?.length && (
                <SimpleNavigation
                  open={openSubNav.includes(index)}
                  menu={item}
                  level={level + 1}
                  desktop={props.desktop}
                  mobile={props.mobile}
                  location={props.location}
                  onCloseMobileMenu={props.onCloseMobileMenu}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default SimpleNavigation;
