import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ArrowDownRight32 } from "@carbon/icons-react";

import LoadingIndicator from "../general-components/loading-indicator";
import FooterSitemap from "./components/footer-sitemap";
import FooterSocialMedia from "./components/footer-social-media";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {
  render() {
    if (this.props.footerConfig && this.props.menu) {
      return (
        <>
          <hr className="footer-line" />
          <div className="container">
            {this.props.footerConfig && this.props.menu && (
              <>
                <div className="row">
                  <div className="col-16 col-lg-5 offset-lg-1 footer-highlight">
                    <section className="footer-highlight">
                      <div className="row align-items-center">
                        {!!this.props.menu.links.length && (
                          <div className="col-8 col-md-5">
                            <nav className="footer-menu">
                              <ul className="row">
                                {this.props.menu.links.map((item, index) => (
                                  <li key={index} className="col">
                                    <Link to={item.url.path}>
                                      <ArrowDownRight32 />
                                      {item.label}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </nav>
                          </div>
                        )}
                        {this.props.footerConfig.fieldLinkNewsletter && (
                          <div className="col-16 col-md">
                            <h3><FormattedMessage id="footer.stay_up_to_date" /></h3>
                            <Link
                              className="btn"
                              to={
                                this.props.footerConfig.fieldLinkNewsletter.url
                                  .path
                              }
                            >
                              <FormattedMessage id="footer.subscribe_to_newsletter" />
                            </Link>
                          </div>
                        )}
                      </div>
                    </section>
                  </div>
                  <div className="col-16 col-lg-5 offset-lg-1 footer-menu">
                    {this.props.footerConfig.fieldFooterSitemapZeigen && (
                      <FooterSitemap
                        menu={
                          this.props.footerConfig.fieldMenue
                            ? this.props.footerConfig.fieldMenue.targetId
                            : "main"
                        }
                      />
                    )}
                    <FooterSocialMedia footerConfig={this.props.footerConfig} />
                  </div>
                  <div className="col-16 col-lg-4 footer-logos">
                    <div className="row">
                      <div className="col">
                        <span className="small">
                          <FormattedMessage id="footer.sponsored_by" />
                        </span>
                        <img src="/logo-foerderung.svg" alt="Logo Sponsoring" />
                      </div>
                      <div className="col">
                        <img
                          src="/icons_accessibility.svg"
                          alt="Logo Sponsoring"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldCopyright: PropTypes.string.isRequired,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  menu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(PageFooter);
