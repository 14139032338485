import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { self } from "../../../../config";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import { Animation } from "../../../../hoc/with-animation";

class ParagraphAkkordionText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <Accordion
                allowZeroExpanded={true}
              >
                {this.props.content.fieldAbschnitte.map((item, index) => (
                  <Animation delay={index * 100} key={index}>
                    <AccordionItem uuid={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="container">
                            <div className="row">
                              <div className="col-14 offset-1">
                                <div className="accordion-button-inner">
                                  {item.entity.fieldTitel}
                                  <img
                                    src={`${self}/icon-accordion.svg`}
                                    className="arrow-icon"
                                    alt={"arrow icon"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="container">
                          <div className="row">
                            <div className="col-14 offset-1">
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html: item.entity.fieldText.processed,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Animation>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphAkkordionText;
