import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use it?
 */
class ParagraphHeroText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-hero-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-14 offset-1">
              <div
                className="hero text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }).isRequired,
};

export default ParagraphHeroText;
