import moment from "moment";
import { drupalFormat } from "../config";

/**
 * Converts date time object into a text formatted time span.
 * @param {String} date.startDate
 * @param {String} date.endDate
 * @returns
 */
const getFormattedTimespan = (date) => {
  // define formats
  const dateTimeFormat = "dddd, DD. MMMM YYYY, HH:mm";
  const dateFormat = "dddd, DD. MMMM YYYY";
  const timeFormat = "HH:mm";

  // moment.tz.setDefault('Europe/Berlin');

  // empty array, gets filled up with formatted date/time partials
  const formattedDate = [];

  const startDate = moment(moment.utc(date.startDate.replace(" UTC", "").trim().replace(" ", "T"), drupalFormat).toDate()).local();
  const endDate = moment(moment.utc(date.endDate.replace(" UTC", "").trim().replace(" ", "T"), drupalFormat).toDate()).local();

  // boolean, if timespan is "full day"
  const fullDay =
    startDate.format(timeFormat) === "00:00" &&
    endDate.format(timeFormat) === "23:59";

  // boolean, if timespan spans across multiple days
  const multipleDays =
    +endDate.format("YYYYMMDD") > +startDate.format("YYYYMMDD");

  // add the partials to the formattedDate array, depending on different scenarios
  if (fullDay) {
    formattedDate.push(startDate.format(dateFormat));

    if (multipleDays) {
      formattedDate.push(endDate.format(dateFormat));
    }
  } else {
    formattedDate.push(startDate.format(dateTimeFormat));

    if (multipleDays) {
      formattedDate.push(endDate.format(dateTimeFormat));
    } else {
      formattedDate.push(endDate.format(timeFormat));
    }
  }

  // join the start and end string with a dash and return it
  return formattedDate.join(" – ");
};

export default getFormattedTimespan;
