import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const TeaserJobOfferOverview = ({ item }) => {
  if (!item?.path?.alias || !item?.entityLabel) return;
  return (
    <div className="row teaser-job-offer">
      <div className="col-14 offset-1">
        <Link to={item.path.alias}>
          <small>
            <FormattedMessage id="job_offer" />
          </small>
          <h3>{item.entityLabel}</h3>
        </Link>
      </div>
    </div>
  );
};

export default TeaserJobOfferOverview;
