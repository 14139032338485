import React from "react";
import TeaserEducationalResourceOverview from "../../../../teaser-base/educational-resource/teaser-educational-resource-overview";
import ErrorBoundary from "../../../../../error-boundary";
import { Animation } from "../../../../../hoc/with-animation";

const ParagraphExtendedTeaserOverviewEducationalResources = ({
  itemsToShow,
  content,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-14 offset-1">
          <div className="row">
            {!!itemsToShow.length &&
              itemsToShow.map((item, index) => (
                <div className="col-third" key={index}>
                  <ErrorBoundary>
                    <Animation delay={index * 100}>
                      <TeaserEducationalResourceOverview
                        item={item}
                        pagerFullPage={
                          content.fieldPagerAufVollseiten
                            ? content.entityId
                            : false
                        }
                      />
                    </Animation>
                  </ErrorBoundary>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParagraphExtendedTeaserOverviewEducationalResources;
