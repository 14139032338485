import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";
import { Animation } from "../../../../hoc/with-animation";
import ComponentBildSlider from "../image-image-slider/component-bild-slider";

class ParagraphTeaserImage extends Component {
  sliderSettings = {
    adaptiveHeight: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    arrows: false,
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
      "theme-1": this.props.content.fieldColorScheme === "theme-1",
      "theme-2": this.props.content.fieldColorScheme === "theme-2",
      "theme-blank":
        !this.props.content.fieldColorScheme ||
        this.props.content.fieldColorScheme === "default",
      "horizontal-line": this.props.content.fieldVerticalLine === true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container-fluid">
          <div
            className={classNames({
              row: true,
              "flex-lg-row-reverse":
                this.props.content.fieldImagePosition === "right",
              "text-img": this.props.content.fieldImagePosition === "right",
              "img-text": this.props.content.fieldImagePosition === "left",
              "slider": this.props.content.fieldImage.length > 1
            })}
          >
            <Animation
              delay={
                this.props.content.fieldImagePosition === "right" ? 200 : 0
              }
            >
              <div className="col-16 col-lg-8 teaser-img-wrap">
                {this.props.content.fieldImage.length === 1 ? (
                  <Image
                    className="teaser-image"
                    data={
                      this.props.content.fieldImage[0].entity.fieldMediaImage
                    }
                    nodeTitle={this.props.content.fieldHeading}
                    caption={
                      this.props.content.fieldImage[0].entity
                        .fieldCaption
                    }
                    credit={this.props.content.fieldImage[0].entity.fieldCredit}
                  />
                ) : (
                  <div className="slider-wrapper">
                    <ComponentBildSlider
                      bilder={this.props.content.fieldImage}
                      sliderSettings={this.sliderSettings}
                    />
                  </div>
                )}
              </div>
            </Animation>
            <Animation
              delay={
                this.props.content.fieldImagePosition === "right" ? 0 : 200
              }
            >
              <div
                className={classNames({
                  "col-14 col-lg-7 offset-1 text-wrap": true,
                  right: this.props.content.fieldImagePosition === "right",
                  left: this.props.content.fieldImagePosition === "left",
                })}
              >
                {this.props.content.fieldText && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldText.processed,
                    }}
                  />
                )}
                {this.props.content.fieldCallToAction && (
                  <div>
                    <Link
                      className="line-link"
                      link={this.props.content.fieldCallToAction}
                    />
                  </div>
                )}
              </div>
            </Animation>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
