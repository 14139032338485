import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Download24 } from "@carbon/icons-react";
import Image from "../../../image/image";
import { self } from "../../../../config";
import index from "react-select";

class ParagraphDownload extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download": true,
    });
    return (
      <section className={sectionClassNames}>
        <a
          href={
            this.props.content.entity.fieldDatei.entity.fieldMediaFile.entity
              .url
          }
          target="_blank"
        >
          <div className="container">
            <div className="row">
              <div className="col-14 offset-1 text flex-wrapper">
                <div className="text">
                  {this.props.content.entity.fieldSubline && (
                    <div
                      className="subline small"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.entity.fieldSubline,
                      }}
                    />
                  )}
                  {this.props.content.entity.fieldTitle && (
                    <p>
                      <strong>{this.props.content.entity.fieldTitle}</strong>
                    </p>
                  )}
                </div>
                <div className="icon-wrapper circle">
                  <span className="icon icon-download" />
                </div>
              </div>
            </div>
          </div>
        </a>
      </section>
    );
  }
}

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    fieldDatei: PropTypes.shape({
      entity: PropTypes.shape({
        fieldVorschaubild: PropTypes.shape({
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
            }),
          }),
        }),
        entityLabel: PropTypes.string,
        fieldBeschreibung: PropTypes.shape({
          processed: PropTypes.string,
        }),
        fieldMediaFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

export default ParagraphDownload;
