import React, { Component } from "react";
import PropTypes from "prop-types";
import { Search, Button } from "carbon-components-react";

class ComponentExtendedTeaserOverviewSearch extends Component {
  state = {};

  search = React.createRef();

  render() {
    return (
      <section className="search">
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-13 offset-md-1">
              <Search
                ref={this.search}
                id="search-1"
                placeHolderText="What are you looking for today?"
                size="xl"
                onChange={(event) =>
                  typeof event.target.value === "undefined" ||
                  event.target.value.length < 3
                    ? this.props.changeActiveSearch()
                    : this.props.changeActiveSearch(
                        this.search.current.input.value
                      )
                }
                labelText="Suche..."
              />
            </div>
            <div className="col-16 col-md-2 d-md-flex justify-content-md-end">
              <Button
                onClick={() =>
                  this.props.changeActiveSearch(this.search.current.input.value)
                }
              >
                Suchen
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewSearch.propTypes = {
  changeActiveSearch: PropTypes.func.isRequired,
};

export default ComponentExtendedTeaserOverviewSearch;
