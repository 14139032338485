import React, { Component } from "react";
import PropTypes from "prop-types";
import { Close32 } from "@carbon/icons-react";

import Image from "../../image/image";
import { teaserPersonPropTypes } from "./teaser-person";
import { FormattedMessage } from "react-intl";

class TeaserPersonOverlay extends Component {
  render() {
    return (
      <article className="node teaser-overlay">
        <section className="teaser-overlay-header">
          {this.props.toggleOverlay && (
            <div className="close" onClick={() => this.props.toggleOverlay()}>
              <Close32 />
            </div>
          )}
        </section>
        <section className="teaser-overlay-body container-fluid">
          <div className="row">
            <div className="col-16">
              <div className="headline-wrapper">
                <h1 className="headline-xl">{this.props.item.title}</h1>
                {!!this.props.item.fieldRolleOderFunktion.length && (
                  <div className="role">
                    <span className="small">
                      {this.props.item.fieldRolleOderFunktion.map(
                        (item, index) => (
                          <React.Fragment key={index}>
                            {item.entity && (
                              <>
                                {item.entity.name}
                                {index !==
                                  this.props.item.fieldRolleOderFunktion
                                    .length -
                                    1 &&
                                  index <
                                    this.props.item.fieldRolleOderFunktion
                                      .length &&
                                  ", "}
                              </>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row flex-md-row-reverse">
            <div className="col-16 col-md-8">
              <div className="image-wrapper">
                {this.props.item.fieldBildWCaption ? (
                  <Image
                    data={
                      this.props.item.fieldBildWCaption.entity.fieldMediaImage
                    }
                    nodeTitle={this.props.item.title}
                    credit={
                      this.props.item.fieldBildWCaption.entity.fieldCredit
                    }
                    caption={
                      this.props.item.fieldBildWCaption.defaultCaption === false
                        ? this.props.item.fieldBildWCaption.customCaption
                        : null
                    }
                  />
                ) : (
                  (() => {
                    switch (this.props.item.fieldGender.entity.name) {
                      case "Weiblich":
                        return <img src="/skeleton-head-female.jpg" />;
                      case "Männlich":
                        return <img src="/skeleton-head-male.jpg" />;
                      default:
                        return <img src="/skeleton-head-male.jpg" />;
                    }
                  })()
                )}
              </div>
            </div>
            <div className="col-16 col-md-8">
              <div className="infos">
                {this.props.item.fieldMail && (
                  <div className="mail-wrapper">
                    <a
                      className="mail"
                      onClick={`mailto:${this.props.item.fieldMail}`}
                    >
                      <FormattedMessage id="contact.send_email" />
                    </a>
                  </div>
                )}
                {this.props.item.fieldTelefon && (
                  <div className="phone-wrapper">
                    <FormattedMessage id="contact.phone" />{" "}
                    <a
                      className="phone"
                      href={`tel:${this.props.item.fieldTelefon
                        .replace(/ /g, "")
                        .replace(/\([\s\S]*?\)/g, "")}`}
                    >
                      {this.props.item.fieldTelefon}
                    </a>
                  </div>
                )}
              </div>
              {this.props.item.fieldBeschreibung && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldBeschreibung.processed,
                  }}
                />
              )}
            </div>

            {/*this.props.item.fieldKontakinformationen.length > 0 && (
              <div className="contact-infos infos">
                {this.props.item.fieldKontakinformationen.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-8">
                      <div className="label">
                        {item.entity.fieldContactType}
                      </div>
                    </div>
                    <div className="col-8">
                      <div
                        className="value"
                        dangerouslySetInnerHTML={{
                          __html: item.entity.fieldWert.processed,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
                      )*/}
          </div>
        </section>
      </article>
    );
  }
}

TeaserPersonOverlay.propTypes = {
  item: teaserPersonPropTypes,
  toggleOverlay: PropTypes.func,
};

export default TeaserPersonOverlay;
