import React, { useEffect, useState } from "react";
import Search from "./search";
import SearchResultTeaser from "./components/search-result-teaser";
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import searchQuery from "./search.graphql";
import { FormattedMessage, injectIntl } from "react-intl";
import LoadingIndicator from "../../general-components/loading-indicator";
import Pagination from "./components/pagination";

/**
 * Searchpage
 */
const Searchpage = (props) => {
  const [results, setResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // if search params contain q param, map to query state
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setQuery(search.get("q"));
  }, [location.search, query]);

  // request search results whenever query changes
  useEffect(() => {
    sendRequest();
  }, [query, offset]);

  const sendRequest = () => {
    // skip request if string is too short
    if (!query) return;

    setLoading(true);

    // send graphql request
    props.client
      .query({
        query: searchQuery,
        variables: {
          searchString: query,
          language: [props.intl.locale],
          offset: offset,
        },
      })
      .then((response) => {
        setResults(response.data.searchAPISearch.documents);
        setResultsCount(response.data.searchAPISearch.result_count);
      })
      .catch((error) => {
        setResults([]);
        setResultsCount(0);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <article className="node-searchpage">
      <div className="container">
        <div className="row">
          <div className="col-14 offset-1">
            <h1 className="headline-l">
              <FormattedMessage id="search.results" />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-14 col-lg-9 offset-1">
            <div className="searchbox-wrapper">
              <h2 className="headline-s searchbox-headline">
                <FormattedMessage id="search.your_search" />
              </h2>
              <Search defaultValue={query} />
              <div className="small search-results-amount">
                {!!results?.length && !loading && (
                  <>
                    {resultsCount} <FormattedMessage id="search.results" />
                  </>
                )}
                {!results?.length && !loading && (
                  <FormattedMessage id="search.no_results" />
                )}
              </div>
            </div>
          </div>
        </div>
        {!!results?.length && !loading && (
          <div className="row">
            <div className="col-14 col-lg-9 offset-1">
              {results.map((result, i) => (
                <SearchResultTeaser key={i} content={result} />
              ))}
            </div>
          </div>
        )}
        {resultsCount > 10 && (
          <div className="row">
            <div className="col-14 col-lg-9 offset-1">
              <Pagination
                total={resultsCount}
                steps={10}
                value={offset}
                onChange={(newOffset) => setOffset(newOffset)}
              />
            </div>
          </div>
        )}
        {loading && <LoadingIndicator />}
      </div>
    </article>
  );
};

export default withApollo(withRouter(injectIntl(Searchpage)));
