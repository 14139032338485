import React from "react";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserJobOfferOverview from "../../../../teaser-base/job-offer/teaser-job-offer-overview";

const ParagraphExtendedTeaserOverviewJobOffers = ({ itemsToShow, content }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-16">
          {!!itemsToShow.length &&
            itemsToShow.map((item, index) => (
              <React.Fragment key={index}>
                <ErrorBoundary>
                  <TeaserJobOfferOverview
                    item={item}
                    pagerFullPage={
                      content.fieldPagerAufVollseiten ? content.entityId : false
                    }
                  />
                </ErrorBoundary>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ParagraphExtendedTeaserOverviewJobOffers;
