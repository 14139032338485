import React, { useEffect } from "react";

import articleQuery from "./article.graphql";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import { i18nActionHelper } from "../i18n/i18n";

// components
import LoadingIndicator from "../general-components/loading-indicator";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import Image from "../general-components/image/image";
import ContentBase from "../general-components/content-base/content-base";
import getFormattedTimespan from "../lib/get-formatted-timespan";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

/**
 *  Article component to render job offers, news, events and press releases
 */
const Article = (props) => {
  // do nothing but show loading indicator until content loaded
  if (!props.data.route) {
    return <LoadingIndicator />;
  }

  useEffect(() => {
    i18nActionHelper(props, props.dispatch);
  }, []);

  // simplify data object route
  const data = props.data.route.entity;
  const modulesPosition = data.fieldModulesPosition || "below";

  const labels = {
    educational_offer: data.fieldLabel,
  };

  const subtitles = {
    veranstaltung:
      data.fieldDatum &&
      data.fieldOrt &&
      `${getFormattedTimespan(data.fieldDatum)}${
        data.fieldOrt && ", " + data.fieldOrt
      }`,
    news: `${moment
      .utc(data.publishedAt.value * 1000)
      .format("dddd, DD. MMMM YYYY")}${data.fieldOrt ? ", " + data.fieldOrt : ""}`,
    press_release: `${moment
      .utc(data.publishedAt.value * 1000)
      .format("dddd, DD. MMMM YYYY")}${data.fieldOrt ? ", " + data.fieldOrt : ""}`,
    educational_offer: data.fieldSubtitle || "",
  };

  return (
    <article className="article">
      <Helmet>
        <title>{`SFVV | ${data.entityLabel}`}</title>
      </Helmet>

      {/* Header section (type, title, date, location) */}
      {data.title && (
        <section className="article-header paragraph">
          <div className="container">
            <div className="row">
              <div className="col-14 col-xl-12 offset-1">
                <div className="small content-type">
                  {data.entityBundle === "educational_offer" ? (
                    data.fieldLabel && data.fieldLabel
                  ) : (
                    <FormattedMessage id={data.entityBundle} />
                  )}
                </div>
                <h1 className="headline-l">{data.title}</h1>
                {subtitles[data.entityBundle] && (
                  <div className="small">{subtitles[data.entityBundle]}</div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="article-content-section paragraph">
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-8">
              {data.fieldImage?.entity?.fieldMediaImage && (
                <Image
                  data={data.fieldImage.entity.fieldMediaImage}
                  style={
                    data.fieldImagePosition
                      ? "style" + data.fieldImagePosition
                      : "style"
                  }
                />
              )}
            </div>
            <div className="col-16 col-lg-6 offset-lg-1">
              {data.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: data.fieldText.processed,
                  }}
                />
              )}
              {data.fieldTicketlink && (
                <div className="ticket-wrapper">
                  <a
                    className="btn btn-primary"
                    href={data.fieldTicketlink.url.path}
                  >
                    <FormattedMessage id="event.tickets" />
                  </a>
                </div>
              )}
              {data.fieldModules && modulesPosition === "right" && (
                <ContentBase content={data.fieldModules} className="article-content" />
              )}
            </div>
          </div>
        </div>
      </section>

      {data.fieldModules && modulesPosition === "below" && (
        <ContentBase content={data.fieldModules} />
      )}
    </article>
  );
};

export default graphql(articleQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Article)));
