import React from "react";
import Image from "../../../image/image";

const GalleryItem = (props) => {
  return (
    <div className="gallery-item" >
      <Image onClick={props.onClick} data={{...props.imageData, style: props.imageData.teaserStyle}} />
      <a className="download-button" href={props.imageData.url} target="_blank">
        <span className="icon icon-arrow-down" />
      </a>
    </div>
  );
};

export default GalleryItem;
