import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphZitat extends Component {
  render() {
    const { fieldImage = null, fieldZitat, fieldQuelle } = this.props.content;

    const sectionClassNames = classNames({
      "paragraph paragraph-zitat": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            {fieldImage !== null && (
              <div className="col-2 offset-md-2">
                <ErrorBoundary>
                  <Image data={fieldImage.entity.fieldMediaImage} />
                </ErrorBoundary>
              </div>
            )}

            <div
              className={classNames({
                "col-10": fieldImage !== null,
                "col-12 offset-md-2": fieldImage === null,
              })}
            >
              <blockquote className="text">
                {fieldZitat}
                <cite>{fieldQuelle}</cite>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphZitat.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
