import { graphql } from "@apollo/client/react/hoc";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import galleryQuery from "./gallery-query.graphql";
import Image from "../../../image/image";
import GalleryItem from "./gallery-item";
import Slider from "react-slick";
import SliderItem from "./slider-item";

const mapStateToProps = (reduxStore) => ({ reduxStore });

const ParagraphGallery = (props) => {
  const [items, setItems] = useState([]);
  const [sliderActive, setSliderActive] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const closeRef = useRef([]);

  const sliderSettings = {
    initialSlide: initialSlide,
    nextArrow: (
      <button aria-label="next">
        <span className="icon icon-arrow-right" />
      </button>
    ),
    prevArrow: (
      <button aria-label="prev">
        <span className="icon icon-arrow-left" />
      </button>
    ),
  };

  // set items array for render
  useEffect(() => {
    if (props.content.fieldManuelleSortierung) {
      setItems(
        props.content.fieldBilder.map((item) => ({
          imageData: item.entity.fieldMediaImage,
          credit: item.entity.fieldCredit,
          caption: item.entity.fieldBildunterschrift,
        }))
      );
    } else {
      setItems(
        props.files.mediaQuery.entities.map((item) => ({
          imageData: item.fieldMediaImage,
          credit: item.fieldCredit,
          caption: item.fieldBildunterschrift,
        }))
      );
    }
  }, []);

  // open slider, set current slide
  const openSlider = (index) => {
    setInitialSlide(index);
    setSliderActive(true);
    document && (document.body.style.overflow = "hidden");
  };

  // close slider if click on overlay or cross
  const closeSlider = (e) => {
    if (!closeRef.current.includes(e.target)) {
      return;
    }

    setInitialSlide(0);
    setSliderActive(false);
    document && (document.body.style.overflow = "auto");
  };

  return (
    <section className="paragraph paragraph-gallery">
      <div className="container">
        <div className="row">
          <div className="col-14 offset-1">
            <small>{props.content.fieldTitle}</small>
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-14 offset-1">
            <div className="row">
              {!!items.length &&
                items.map((item, index) => (
                  <div className="col-third" key={index}>
                    <GalleryItem
                      imageData={item.imageData}
                      onClick={() => openSlider(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {sliderActive && (
        <div
          className="slider-overlay"
          onClick={(e) => closeSlider(e)}
          ref={(el) => (closeRef.current[0] = el)}
        >
          <div
            className="close-overlay"
            ref={(el) => (closeRef.current[1] = el)}
          >
            <span
              className="icon icon-cross"
              ref={(el) => (closeRef.current[2] = el)}
            />
          </div>
          <div className="slider-wrapper">
            <Slider {...sliderSettings}>
              {!!items.length &&
                items.map((item, index) => (
                  <SliderItem item={item} key={index} />
                ))}
            </Slider>
          </div>
        </div>
      )}
    </section>
  );
};

export default connect(mapStateToProps)(
  graphql(galleryQuery, {
    name: "files",
    skip: (props) => props.content.fieldManuelleSortierung,
    options: (props) => ({
      variables: {
        limit: 30,
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
        tagsEnabled: props.content.fieldSchlagwort.length > 0,
      },
    }),
  })(ParagraphGallery)
);
