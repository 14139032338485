import React, { Component } from "react";
import Loader from "react-loader-spinner";

class LoadingIndicator extends Component {
  render() {
    return (
      <div className="loading-indicator-wrap">
        <Loader
          type="TailSpin"
          color="var(--grey-30)"
          height={100}
          width={100}
          timeout={50000}
        />
      </div>
    );
  }
}

export default LoadingIndicator;
