import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorBoundary from "../../../../error-boundary";
import ParagraphDownload from "../download/paragraph-download";
class ParagraphDownloadOverview extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download-module": true,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldDownloadModule &&
          this.props.content.fieldDownloadModule.map((item, index) => (
            <React.Fragment key={index}>
              <ErrorBoundary>
                <ParagraphDownload content={item} />
              </ErrorBoundary>
            </React.Fragment>
          ))}
      </section>
    );
  }
}

ParagraphDownloadOverview.propTypes = {
  content: PropTypes.shape({
    fieldDownloadModule: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldDownloadModule: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphDownloadOverview;
