import React from "react";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import EditButton from "../../../backend/edit-button";
import { Link } from "react-router-dom";
import { pagerFullPageContextAction } from "../../../app-actions";
import getFormattedTimespan from "../../../lib/get-formatted-timespan";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserArticle = (props) => {
  if (!props.item) return;

  const formattedMeta =
    (props.item.fieldDatum && getFormattedTimespan(props.item.fieldDatum)) ||
    (props.item.fieldReferencedContent?.entity.fieldDatum &&
      getFormattedTimespan(
        props.item.fieldReferencedContent.entity.fieldDatum
      ));
  
  const tags = props.item.fieldSchlagwort?.map((tag) => tag.entity.name).join(", ");

  const image =
    props.item.fieldTeaserbild?.entity.fieldMediaImage ||
    props.item.fieldBild?.entity.fieldMediaImage;

  const link =
    props.item.fieldReferencedContent?.entity.entityUrl.path ||
    props.item.entityUrl?.path ||
    props.item.path?.alias ||
    props.item.fieldLink?.url.path;

  return (
    <article
      className={`node node-${
        props.item.entityId || "article"
      } node-teaser node-teaser-default teaser-article col-16`}
    >
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location?.pathname}
      />
      <Link
        onClick={() =>
          props.dispatch(pagerFullPageContextAction(props.pagerFullPage))
        }
        className="flex-wrap"
        to={link}
      >
        {image && (
          <Image
            data={{
              ...image,
              style: image[props.imgStyle || "style"],
            }}
            nodeTitle={props.item.title}
          />
        )}
        {(formattedMeta || props.item.fieldLabel || props.item.title) && (
          <div className="text-wrapper">
            {tags && <small>{tags}</small>}
            {formattedMeta && <small>{formattedMeta}</small>}
            {props.item.fieldLabel && <small>{props.item.fieldLabel}</small>}
            {/* {date && <FieldTime date={date.startDate} format={"HH:mm"}/>} */}
            <h3>{props.item.title}</h3>
          </div>
        )}
      </Link>
    </article>
  );
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserArticle)));
