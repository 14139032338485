import React from "react";
import PropTypes from "prop-types";

const ParagraphDownloadOverviewSimple = ({ content }) => {
  const downloads = content.fieldDownloadModule;
  return (
    <section className="paragraph paragraph-download-overview-simple">
      <div className="container">
        <div className="row">
          <div className="col-14 offset-1 text">
            {content.fieldTitle && (
              <p>
                <b>{content.fieldTitle}</b>
              </p>
            )}
            <ul>
              {downloads.map((item, i) => (
                <li key={i}>
                  <a
                    href={
                      item.entity.fieldDatei.entity.fieldMediaFile.entity.url
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.entity.fieldTitle}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDownloadOverviewSimple.propTypes = {
  content: PropTypes.shape({
    fieldDownloadModule: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitle: PropTypes.string,
          fieldSubline: PropTypes.string,
          fieldDatei: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphDownloadOverviewSimple;
