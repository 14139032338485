import Fade from 'react-reveal/Fade';
import React from "react";

/**
 * A HOC to wrap a component with a specified element that handles the scroll animation
 * @param {Component} Component
 * @returns
 */

const Animation = (props) => {
  return (
    <Fade {...props}>
      {props.children}
    </Fade>
  )
}

export { Animation };
