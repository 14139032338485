import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment";
import { connect } from "react-redux";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import teaserNodeQueryEventDateFiltered from "../../../teaser-base/queries/teaser-node-query-event-date-filtered.graphql";
import TeaserBase from "../../../teaser-base/teaser-base";
import { kalenderMonatSetMonthAction } from "./actions-kalender-monat";
import ErrorBoundary from "../../../../error-boundary";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{kalenderConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  kalenderConfig: reduxStore.kalenderMonth.months,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ParagraphKalenderMonat extends Component {
  getDaysInMonth = (month, year) => {
    let days = [];

    for (let i = 1; i <= moment(month, "MMMM").daysInMonth(); i++) {
      // TODO: If i = 1, then i = 01.
      days = [
        ...days,
        moment(
          `${moment(year, "YYYY").format("YYYY")}-${moment(
            month,
            "MMMM"
          ).format("MM")}-${i}`
        ),
      ];
    }

    return days;
  };

  static mapDatesOnInit = (date, type, staticDate) => {
    if (date === "this month") {
      if (type === "month") {
        return moment().format("MMMM");
      }

      return moment().format("YYYY");
    }
    if (date === "next month") {
      if (type === "month") {
        return moment().add(1, "month").format("MMMM");
      }

      return moment().add(1, "month").format("YYYY");
    }

    if (type === "month") {
      return moment
        .utc(staticDate, "YYYY-MM-DDTHH:mm:ss")
        .local()
        .format("MMMM");
    }

    return moment.utc(staticDate, "YYYY-MM-DDTHH:mm:ss").local().format("YYYY");
  };

  state = {
    datesInMonth: this.getDaysInMonth(
      ParagraphKalenderMonat.mapDatesOnInit(
        this.props.content.fieldStartmonat,
        "month",
        this.props.content.fieldStartmonatStaic.value
      ),
      ParagraphKalenderMonat.mapDatesOnInit(
        this.props.content.fieldStartmonat,
        "year",
        this.props.content.fieldStartmonatStaic.value
      )
    ),
    currentMonth: ParagraphKalenderMonat.mapDatesOnInit(
      this.props.content.fieldStartmonat,
      "month",
      this.props.content.fieldStartmonatStaic.value
    ),
    currentYear: ParagraphKalenderMonat.mapDatesOnInit(
      this.props.content.fieldStartmonat,
      "year",
      this.props.content.fieldStartmonatStaic.value
    ),
    currentDay: false,
    currentDayEvents: [],
  };

  openCalendar = (item) => {
    if (this.state.currentDay === item) {
      this.setState({
        currentDay: false,
        currentDayEvents: [],
      });
    } else {
      this.setState({
        currentDay: item,
        currentDayEvents: this.props.nodes.nodeQuery.entities.filter(
          (event) =>
            moment
              .utc(event.fieldDatum.value, "YYYY-MM-DDTHH:mm:ss")
              .local()
              .format("YYY-MM-DD") === moment(item).format("YYY-MM-DD")
        ),
      });
    }
  };

  monthPager = (direction) => {
    this.setState({
      currentDay: false,
      currentDayEvents: [],
    });

    if (direction === "next") {
      const month = moment(this.state.datesInMonth[0]).add(1, "months");

      this.setState({
        currentMonth: month.format("MMMM"),
        currentYear: month.format("YYYY"),
        datesInMonth: this.getDaysInMonth(
          month.format("MMMM"),
          month.format("YYYY")
        ),
      });

      this.props.dispatch(
        kalenderMonatSetMonthAction({
          date: month,
          id: this.props.content.entityId,
        })
      );
    } else {
      const month = moment(this.state.datesInMonth[0]).subtract(1, "months");

      this.setState({
        currentMonth: month.format("MMMM"),
        currentYear: month.format("YYYY"),
        datesInMonth: this.getDaysInMonth(
          month.format("MMMM"),
          month.format("YYYY")
        ),
      });

      this.props.dispatch(
        kalenderMonatSetMonthAction({
          date: month,
          id: this.props.content.entityId,
        })
      );
    }
  };

  handleKeyDownPager = (event, action) => {
    if (event.key === "Enter") {
      this.monthPager(action);
    }
  };

  handleOpenCalendar = (event, item) => {
    if (event.key === "Enter") {
      this.openCalendar(item);
    }
  };

  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-kalender-monat": true,
      }),
      beginOfMonthOffsetDays = moment(this.state.datesInMonth[0]).format("e"),
      endOfMonthOffsetDays =
        6 -
        parseInt(
          moment(
            this.state.datesInMonth[this.state.datesInMonth.length - 1]
          ).format("e"),
          10
        );

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 d-flex justify-content-between">
              {this.props.content.fieldMonatsumschalterZeigen && (
                <div
                  className="pager"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.monthPager("prev")}
                  onKeyDown={(event) => this.handleKeyDownPager(event, "prev")}
                >
                  <ArrowLeft32 />
                </div>
              )}
              <h3 className="calendar-heading">
                {this.state.currentMonth} {this.state.currentYear}
              </h3>
              {this.props.content.fieldMonatsumschalterZeigen && (
                <div
                  className="pager"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.monthPager("next")}
                  onKeyDown={(event) => this.handleKeyDownPager(event, "next")}
                >
                  <ArrowRight32 />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-16">
            <div className="row header">
              {["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    "col cal-header-cell": true,
                    "offset-1": index === 0,
                  })}
                >
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className="row">
              {[...Array(parseInt(beginOfMonthOffsetDays, 10))].map(
                (item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      "col empty-cal-cell": true,
                      "offset-1": index === 0,
                    })}
                  />
                )
              )}

              {this.state.datesInMonth.map((item, index) => (
                <React.Fragment key={index}>
                  {item.isValid() && (
                    <>
                      <div
                        className={classNames({
                          "col cal-cell": true,
                          "has-events":
                            this.props.nodes &&
                            this.props.nodes.nodeQuery &&
                            this.props.nodes.nodeQuery.entities.filter(
                              (event) =>
                                moment
                                  .utc(
                                    event.fieldDatum.value,
                                    "YYYY-MM-DDTHH:mm:ss"
                                  )
                                  .local()
                                  .format("YYYY-MM-DD") ===
                                moment(item).format("YYYY-MM-DD")
                            ).length > 0,
                          [`cell-${index}`]: true,
                          active:
                            moment(this.state.currentDay).format("DD") ===
                            moment(item).format("DD"),
                          "offset-1":
                            (parseInt(beginOfMonthOffsetDays, 10) +
                              parseInt(index, 10)) %
                              7 ===
                            0,
                        })}
                      >
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={() => this.openCalendar(item)}
                          onKeyDown={(event) =>
                            this.handleOpenCalendar(event, item)
                          }
                        >
                          {moment(item).format("DD")}
                        </span>
                      </div>

                      {(index + parseInt(beginOfMonthOffsetDays, 10) + 1) %
                        7 ===
                        0 && (
                        <div
                          className={classNames({
                            "col-16 cal-content-cell": true,
                            [`cell-${index}`]: true,
                            active:
                              this.state.currentDay &&
                              this.state.currentDayEvents.length > 0 &&
                              parseInt(
                                moment(this.state.currentDay)
                                  .endOf("week")
                                  .format("D"),
                                10
                              ) ===
                                index + 1,
                          })}
                        >
                          {this.state.currentDay &&
                            this.state.currentDayEvents.length > 0 &&
                            parseInt(
                              moment(this.state.currentDay)
                                .endOf("week")
                                .format("D"),
                              10
                            ) ===
                              index + 1 && (
                              <>
                                {this.state.currentDayEvents.map(
                                  (eventItem, eventIndex) => (
                                    <ErrorBoundary key={eventIndex}>
                                      <TeaserBase item={eventItem} />
                                    </ErrorBoundary>
                                  )
                                )}
                              </>
                            )}
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}

              {Number.isInteger(endOfMonthOffsetDays) &&
                [...Array(endOfMonthOffsetDays)].map((item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      "col empty-cal-cell": true,
                    })}
                  />
                ))}

              {(beginOfMonthOffsetDays +
                endOfMonthOffsetDays +
                this.state.datesInMonth.length) %
                7 !==
                0 && (
                <div
                  className={classNames({
                    "col-16 cal-content-cell": true,
                    active:
                      this.state.currentDay &&
                      this.state.currentDayEvents.length > 0 &&
                      parseInt(
                        moment(this.state.currentDay).endOf("week").format("D"),
                        10
                      ) <
                        parseInt(moment(this.state.currentDay).format("D"), 10),
                  })}
                >
                  {this.state.currentDay &&
                    this.state.currentDayEvents.length > 0 &&
                    parseInt(
                      moment(this.state.currentDay).endOf("week").format("D"),
                      10
                    ) <
                      parseInt(
                        moment(this.state.currentDay).format("D"),
                        10
                      ) && (
                      <>
                        {this.state.currentDayEvents.map(
                          (eventItem, eventIndex) => (
                            <ErrorBoundary key={eventIndex}>
                              <TeaserBase item={eventItem} />
                            </ErrorBoundary>
                          )
                        )}
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphKalenderMonat.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldMonatsumschalterZeigen: PropTypes.bool,
    fieldStartmonat: PropTypes.oneOf(["this month", "next month", "static"]),
    fieldStartmonatStaic: PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.shape({
    nodeQuery: PropTypes.shape({
      entities: PropTypes.arrayOf(teaserEventPropTypes),
    }),
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryEventDateFiltered, {
    name: "nodes",
    options: (props) => {
      const config = props.kalenderConfig.filter(
        (calendarConfig) => calendarConfig.id === props.content.entityId
      );

      let end_date = moment().endOf("month").format("YYYY-MM-DD"),
        date = moment().startOf("month").format("YYYY-MM-DD");

      if (config.length) {
        end_date = config[0].date.endOf("month").format("YYYY-MM-DD");
        date = config[0].date.startOf("month").format("YYYY-MM-DD");
      } else {
        const currentMonth = ParagraphKalenderMonat.mapDatesOnInit(
            props.content.fieldStartmonat,
            "month"
          ),
          currentYear = ParagraphKalenderMonat.mapDatesOnInit(
            props.content.fieldStartmonat,
            "year"
          );

        end_date = moment(`${currentMonth}-${currentYear}`, "MMMM-YYYY")
          .endOf("month")
          .format("YYYY-MM-DD");
        date = moment(`${currentMonth}-${currentYear}`, "MMMM-YYYY")
          .startOf("month")
          .format("YYYY-MM-DD");
      }

      return {
        variables: {
          type: "veranstaltung",
          end_date: end_date,
          date: date,
          language: props.currentLanguage.toUpperCase(),
        },
      };
    },
  })(ParagraphKalenderMonat)
);
