import React, { useEffect, useRef } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import classNames from "classnames";
import menuQuery from "../../general-components/menu-query.graphql";

const VisitorMenu = (props) => {
  const visitorMenuRef = useRef();

  useEffect(() => {
    const outsideClickHandler = (e) => {
      !visitorMenuRef.current.contains(e.target) && props.onClose();
    };

    setTimeout(() => {
      document.addEventListener("click", outsideClickHandler);
    }, 50);

    return () => {
      document.removeEventListener("click", outsideClickHandler);
    };
  }, [props.onClose]);

  return (
    <div
      className="visitor-menu"
      onClick={props.onClose ? props.onClose : null}
      ref={visitorMenuRef}
    >
      <div className="language-switch">
        <Link
          to={
            props.i18n.currentContentGermanTranslation
              ? props.i18n.currentContentGermanTranslation
              : "#"
          }
          className={classNames({
            active: props.i18n.currentLanguage === "de",
            "not-translated": !props.i18n.currentContentGermanTranslation,
          })}
          onClick={props.onClick}
        >
          Deutsch
        </Link>
        <Link
          to={
            props.i18n.currentContentEnglishTranslation
              ? props.i18n.currentContentEnglishTranslation
              : "#"
          }
          className={classNames({
            active: props.i18n.currentLanguage === "en",
            "not-translated": !props.i18n.currentContentEnglishTranslation,
          })}
          onClick={props.onClick}
        >
          Englisch
        </Link>
      </div>
      <div className="visitor-info">
        <small className="label">Visitor info only</small>
        {!props.visitorInfo.loading && (
          <ul>
            {props.visitorInfo.menuByName.links.map((link, i) => (
              <li key={i}>
                <Link
                  to={link.url.path}
                  onClick={props.onClose ? props.onClose : null}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default graphql(menuQuery, {
  options: {
    variables: {
      name: "visitor-info",
    },
  },
  name: "visitorInfo",
})(VisitorMenu);
