import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const SearchResultTeaser = ({ content }) => {
  const linkLabel = (() => {
    switch (content.type) {
      case "news":
        return "search.to_article";
      case "veranstaltung":
        return "search.to_event";
      case "press_release":
        return "search.to_press_release";
      case "job_offer":
        return "search.to_job_offer";
      case "landingpage":
      default:
        return "search.to_page";
    }
  })();

  const teaserText = (() => {
    switch (content.type) {
      case "news":
        return
        //return <p>Example Text</p>;
      case "veranstaltung":
        return (
          <p>
            {moment.unix(content.date).format("DD. MMMM YYYY")},{" "}
            {content.location}.
          </p>
        );
      case "press_release":
        return
        //return (
        //  <div dangerouslySetInnerHTML={{ __html: content.teaser_text }} />
        //);
      case "job_offer":
        return
        //return <p>Example Text</p>;
      case "landingpage":
      default:
        return
        //return <p>Example Text</p>;
    }
  })();

  return (
    <div className="search-result-teaser">
      <h3 className="headline-s">{content.title}</h3>
      {teaserText && <div className="result-body">{teaserText}</div>}
      <Link to={content.url} className="result-link line-link">
        <FormattedMessage id={linkLabel} />
      </Link>
    </div>
  );
};

export default SearchResultTeaser;
