import React, { useEffect, useState, useRef } from "react";

import { graphql } from "@apollo/client/react/hoc";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import accessibilityMenuConfigQuery from "./accessibility-menu/accessibility-menu-config-query.graphql";

import LanguageSwitch from "./accessibility-menu/language-switch";
import ThemeSwitch from "./accessibility-menu/theme-switch";
import FontSizeSwitch from "./accessibility-menu/font-size-switch";
import { useQuery } from "@apollo/client";
import SocialMedia from "./social-media/social-media";

const HeaderAccessibility = ({ socialMediaConfig }) => {
  const [visitorOpen, setVisitorOpen] = useState(false);
  const [fontSettingsOpen, setFontSettingsOpen] = useState(false);

  const headerMetaSubRef = useRef();

  const language = useSelector((store) => store.i18n.currentLanguage);
  const { data } = useQuery(accessibilityMenuConfigQuery);

  let simpleSpeech, signLanguage;

  const {
    fieldLinkSimpleSpeech,
    fieldLinkSignLanguage,
    fieldLinkSimpleSpeechEn,
    fieldLinkSignLanguageEn,
  } = data?.configPagesById || {};

  switch (language) {
    case "de":
      simpleSpeech = fieldLinkSimpleSpeech;
      signLanguage = fieldLinkSignLanguage;
      break;
    case "en":
    default:
      simpleSpeech = fieldLinkSimpleSpeechEn;
      signLanguage = fieldLinkSignLanguageEn;
      break;
  }

  const closeAllSubMenus = () => {
    setFontSettingsOpen(false);
    setVisitorOpen(false);
  };

  const toggleFontSizeSettings = () => {
    setFontSettingsOpen((prevFontSettingsOpen) => {
      setVisitorOpen(false);
      return !prevFontSettingsOpen;
    });
  };

  const toggleVisitorMenu = () => {
    setVisitorOpen((prevVisitorOpen) => {
      setFontSettingsOpen(false);
      return !prevVisitorOpen;
    });
  };

  return (
    <div className="header-meta">
      <SocialMedia socialMediaConfig={socialMediaConfig} />
      <nav>
        <Link to={simpleSpeech?.url?.path} className="link">
          <span className="icon-wrapper">
            <span className="icon icon-simple-speech"></span>
          </span>
          <span className="label">{simpleSpeech?.title}</span>
        </Link>
        <Link to={signLanguage?.url?.path} className="link">
          <span className="icon-wrapper">
            <span className="icon icon-sign-language"></span>
          </span>
          <span className="label">{signLanguage?.title}</span>
        </Link>
        <FontSizeSwitch
          min={1}
          max={1.6}
          step={0.01}
          destination={headerMetaSubRef.current}
          open={fontSettingsOpen}
          onClick={toggleFontSizeSettings}
          onClose={closeAllSubMenus}
        />
        <ThemeSwitch />
        <LanguageSwitch
          destination={headerMetaSubRef.current}
          open={visitorOpen}
          onClick={toggleVisitorMenu}
          onClose={closeAllSubMenus}
        />
      </nav>
      <div className="header-meta-sub-wrapper" ref={headerMetaSubRef} />
    </div>
  );
};

export default HeaderAccessibility;
